import { ProductionOrderChangeResource } from '@/resource/model';
import { OrderChangeStatusEnum } from '@/resource/enum/order-change-status';
import { OsTable } from '@/components';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { PagingMixin } from '@/mixins/paging';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import { messageError, messageErrors, showWarningConfirm, translation } from '@/utils';
import { Message } from 'element-ui';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { dateFormat } from '@/filters';
import { productionOrderChangeService } from '@/api';

@Component({
  name: 'ProductionOrderChange'
})
export default class ProductionOrderChange extends mixins(PagingMixin, CustomColumnMixin) {
  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<ProductionOrderChangeResource> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small'
  };

  /**
   * 查询条件UI配置
   */
  public queryFormOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'status',
      label: 'orderChange.status',
      option: {
        placeholder: 'orderChange.selectStatus'
      },
      optionData: productionOrderChangeService.getStatusSelectableList()
    },
    {
      type: 'DateRangePicker',
      field: 'createDate',
      label: 'orderChange.changeDate',
      option: {
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();

            return callbackDateTime >= currentDateTime;
          }
        }
      }
    }
  ];

  /**
   * 查询表单对象
   */
  public queryForm: Partial<{
    keywords: string;
    status: OrderChangeStatusEnum | null;
    createDate: Array<string>;
    startDate: string | null;
    endDate: string | null;
  }> = {
    keywords: '',
    status: null,
    createDate: [],
    startDate: null,
    endDate: null
  };

  /**
   * 表格工具栏配置
   */
  public tableToolbarOptions: Array<OperationOption> = [
    {
      operationType: 'batchPush',
      slot: 'start',
      label: 'button.push',
      icon: 'el-icon-check',
      type: 'primary',
      disabled: true,
      permissionCode: 'production:change:batchPlaceOrder',
      handleClick: (): void => {
        this.batchPush();
      }
    },
    {
      operationType: 'batchCancel',
      slot: 'start',
      label: 'button.cancel',
      icon: 'el-icon-close',
      plain: true,
      disabled: true,
      permissionCode: 'production:change:batchCancel',
      handleClick: (): void => {
        this.batchCancel();
      }
    },
    {
      operationType: 'batchDelete',
      slot: 'start',
      label: 'button.delete',
      icon: 'el-icon-delete',
      type: 'danger',
      plain: true,
      disabled: true,
      permissionCode: 'production:change:batch',
      handleClick: (): void => {
        this.batchDelete();
      }
    }
  ];

  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<ProductionOrderChangeResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'code',
      label: 'orderChange.code',
      minWidth: '200px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'orderCode',
      label: 'orderChange.orderCode',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'projectName',
      label: 'orderChange.projectName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerName',
      label: 'orderChange.customerName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'supplierName',
      label: 'orderChange.supplierName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'orderChange.status',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'reason',
      label: 'orderChange.changeReason',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'refuseReason',
      label: 'orderChange.rejectReason',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createUserName',
      label: 'orderChange.changeMan',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'orderChange.changeTime',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  /**
   * 表格行操作配置
   */
  public rowOperationOption: RowOperation<ProductionOrderChangeResource> = {
    fixed: 'right',
    width: '220px',
    operations: [
      {
        operationType: 'push',
        type: 'text',
        label: 'button.push',
        icon: 'el-icon-check',
        size: 'small',
        permissionCode: 'production:change:placeOrder',
        handleClick: (row: ProductionOrderChangeResource): void => {
          this.push([row.id]);
        },
        dynamicHidden: (rowData: ProductionOrderChangeResource): boolean => {
          return rowData.status !== OrderChangeStatusEnum.new;
        }
      },
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        size: 'small',
        permissionCode: 'production:change:edit',
        handleClick: (row: ProductionOrderChangeResource): void => {
          this.goEdit(row);
        },
        dynamicHidden: (rowData: ProductionOrderChangeResource): boolean => {
          return rowData.status !== OrderChangeStatusEnum.new;
        }
      },
      {
        operationType: 'cancel',
        type: 'text',
        label: 'button.cancel',
        icon: 'el-icon-close',
        size: 'small',
        permissionCode: 'production:change:cancel',
        handleClick: (row: ProductionOrderChangeResource): void => {
          this.cancel([row.id]);
        },
        dynamicHidden: (rowData: ProductionOrderChangeResource): boolean => {
          return rowData.status !== OrderChangeStatusEnum.pendingConfirm;
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        size: 'small',
        permissionCode: 'production:change:delete',
        handleClick: (row: ProductionOrderChangeResource): void => {
          this.delete([row.id]);
        },
        dynamicHidden: (rowData: ProductionOrderChangeResource): boolean => {
          return rowData.status !== OrderChangeStatusEnum.new;
        }
      }
    ]
  };

  /**
   * 选中数据缓存对象
   */
  private selectedRows: Array<ProductionOrderChangeResource> = [];

  public activated(): void {
    this.reloadData();
  }

  public created(): void {
    this.initColumns(this.tableColumnOptions, 'productionOrderChange');
  }

  /**
   * 去单据详情
   * @param rowData 行数据
   */
  public goDetail(rowData: ProductionOrderChangeResource): void {
    this.$router.push({
      path: '/production-order-change-detail',
      query: {
        id: rowData.id.toString()
      }
    });
  }

  /**
   * 检索按钮
   */
  public handleQueryClick(): void {
    this.loadData();
  }

  /**
   * 重新加载数据
   */
  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.tableRef as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: OrderChangeStatusEnum): string {
    return productionOrderChangeService.getStatusI18Key(status);
  }

  /**
   * 根据状态获取class
   * @param status  状态值
   * @returns
   */
  public getStatusClass(status: OrderChangeStatusEnum): string {
    return productionOrderChangeService.getStatusClass(status);
  }

  /**
   * 分页切换回调
   */
  public pagingData(): void {
    this.loadData();
  }

  /**
   * 表格行选中事件
   */
  public handleSelectionChange(selectedData: Array<ProductionOrderChangeResource>): void {
    this.selectedRows = selectedData;
  }

  /**
   * 表格行选中数据监听
   */
  @Watch('selectedRows')
  public handleSelectionChanged(value: Array<ProductionOrderChangeResource>): void {
    this.tableToolbarOptions.forEach(option => {
      option.disabled = value.length === 0;
    });
  }

  @Watch('queryForm.createDate')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.startDate = undefined;
      this.queryForm.endDate = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.startDate = dateFormat(value[0]);
      this.queryForm.endDate = `${dateFormat(value[1], 'YYYY-MM-DD')} 23:59:59`;
    }
  }

  /**
   * 数据加载
   */
  private loadData(): void {
    this.tableOption.loading = true;
    productionOrderChangeService
      .getList(this.queryForm as ProductionOrderChangeResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  /**
   * 批量取消
   */
  private batchCancel(): void {
    const availableRows = this.selectedRows.filter(row => row.status === OrderChangeStatusEnum.pendingConfirm);

    if (availableRows.length <= 0) {
      Message.warning(translation('orderChange.selectPendingApproval'));
      return;
    }
    const idList: Array<number> = availableRows.map(row => row.id);
    this.cancel(idList);
  }
  /**
   * 取消
   * @param idList 单据ID集合
   */
  private cancel(idList: Array<number>): void {
    showWarningConfirm(translation('orderChange.confirmCancel'))
      .then(async () => {
        try {
          await productionOrderChangeService.batchCancel(idList);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageErrors(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  /**
   * 批量推送
   */
  private batchPush(): void {
    const availableRows = this.selectedRows.filter(row => row.status === OrderChangeStatusEnum.new);

    if (availableRows.length <= 0) {
      Message.warning(translation('orderChange.selectNewOrFailed'));
      return;
    }
    const idList: Array<number> = availableRows.map(row => row.id);
    this.push(idList);
  }

  /**
   * 推送
   * @param idList 单据ID集合
   */
  private push(idList: Array<number>): void {
    showWarningConfirm(translation('orderChange.submitTip'))
      .then(async () => {
        try {
          await productionOrderChangeService.batchPush(idList);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageErrors(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  /**
   * 批量删除
   */
  private batchDelete(): void {
    const availableRows = this.selectedRows.filter(row => row.status === OrderChangeStatusEnum.new);

    if (availableRows.length <= 0) {
      Message.warning(translation('orderChange.selectNewOrFailed'));
      return;
    }
    const idList: Array<number> = availableRows.map(row => row.id);
    this.delete(idList);
  }

  /**
   * 删除
   * @param idList 单据ID集合
   */
  private delete(idList: Array<number>): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await productionOrderChangeService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageErrors(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  /**
   * 去编辑页面
   * @param rowData 数据对象
   */
  private goEdit(rowData: ProductionOrderChangeResource): void {
    this.$router.push({
      path: '/add-production-order-change',
      query: {
        id: rowData.id.toString()
      }
    });
  }
}
